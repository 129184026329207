export default [

    {
        path: 'VisitsReport',
        meta: { label: 'Visits Report', permission: 'show_reports_overall_visits' },
        name: 'visits-report',
        component: () => import('./../../views/reports/VisitsReport.vue'),
    },
    {
        path: ':id',
        meta: { label: 'Visits Report', permission: 'create_pv_request' },
        name: 'PV',
        component: () => import('./../../views/reports/PV.vue'),
    },
    // {
    //     path: 'map',
    //     meta: { label: 'Visit Map', permission: 'show_reports_overall_visits' },
    //     name: 'visit-map',
    //     beforeEnter (to, from, next) {
    //        if(to.params.position!=null){
    //         next()
    //         return true
    //        }
    //       next({name:'visits-report'})
    //     },
    //     beforeLeave (to, from, next) {
    //        console.log(from)
    //     },
    //     component: () => import('./../../views/reports/visitMap'),
    // },
    {
        path: 'visitsStatistics',
        meta: { label: 'Visits Statistics Report', permission: 'show_reports_visits_statistics' },
        name: 'visitsStatistics',
        component: () => import('./../../views/reports/VisitsStatisticsReport.vue'),
    },
    {
        path: 'coverage-report',
        meta: { label: 'Coverage Report', permission: 'show_reports_coverage' },
        name: 'coverage-report',
        component: () => import('./../../views/reports/CoverageReportV2'),
    },

    {
        path: 'managers-report',
        meta: { label: 'Managers Report', permission: 'show_reports_managers' },
        name: 'managers-report',
        component: () => import('./../../views/reports/ManagersReport'),
    },

    {
        path: 'call-rate-report',
        meta: { label: 'Call Rate', permission: 'show_reports_call_rate' },
        name: 'call-rate-report',
        component: () => import('./../../views/reports/CallRateReport'),
    },
    {
        path: 'frequency-report',
        meta: { label: 'Frequency', permission: 'show_reports_frequency' },
        name: 'frequency-report',
        component: () => import('./../../views/reports/FrequencyReport.vue'),
    },
    {
        path: 'start-point-report',
        meta: { label: 'Start Point', permission: 'show_reports_start_point' },
        name: 'start-point-report',
        component: () => import('./../../views/reports/SalesStartPointReport.vue'),
    },
    {
        path: 'detailing-statistics-report',
        meta: { label: 'Detailing Statistics', permission: 'show_reports_detailing_statistics' },
        name: 'detailing-statistics-report',
        component: () => import('./../../views/reports/DetailingStatisticsReport.vue'),
    },

    {
        path: 'product-frequency-report',
        meta: { label: 'Product Calls', permission: 'show_reports_product_frequency' },
        name: 'product-frequency-report',
        component: () => import('./../../views/reports/ProductFrequencyReport.vue'),
    },

    {
        path: 'speciality-statistics-report',
        meta: { label: 'Speciality Visits Statistics', permission: 'show_reports_speciality_statistics' },
        name: 'speciality-statistics-report',
        component: () => import('./../../views/reports/VisitSpecialitiesStatsReport.vue'),
    },
    {
        path: 'doctor-tracing-report',
        meta: { label: 'Doctor Tracing', permission: 'show_reports_doctor_tracing' },
        name: 'doctor-tracing-report',
        component: () => import('./../../views/reports/DoctorTracingReport.vue'),
    },
    {
        path: 'customer-visits-report',
        meta: { label: 'Customer Visits', permission: 'show_reports_customer_visits' },
        name: 'customer-visits-report',
        component: () => import('./../../views/reports/CustomerVisitsReport.vue'),
    },
    {
        path: 'samples-consumption-report',
        meta: { label: 'Samples Consumption', permission: 'show_reports_samples_consumption' },
        name: 'samples-consumption-report',
        component: () => import('./../../views/reports/SampleConsumptionReport.vue'),
    },
    {
        path: 'giveaways-consumption-report',
        meta: { label: 'Giveaways Consumption', permission: 'show_reports_giveaways_consumption' },
        name: 'giveaways-consumption-report',
        component: () => import('./../../views/reports/GiveawayConsumptionReport.vue'),
    },
]
